<template>
  <div class="podrProdList">
    <el-table :data="podrForm.podr_fees_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="费用名">
        <template slot="header">
          <span style="color: red">费用名</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_fees_list.' + scope.$index + '.podr_fees_name'">
            <el-input v-model="podrForm.podr_fees_list[scope.$index].podr_fees_name" maxlength="30" show-word-limit placeholder="暂无费用名"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额(元)">
        <template slot="header">
          <span style="color: red">金额(元)</span>
        </template>
        <template slot-scope="scope">
          <el-form-item v-if="!isQCDepartment" :prop="'podr_fees_list.' + scope.$index + '.podr_fees_usd'" label-width="0">
            <el-input
              v-model="podrForm.podr_fees_list[scope.$index].podr_fees_usd"
              @input="podrForm.podr_fees_list[scope.$index].podr_fees_usd = keep2DecimalContainNegative(podrForm.podr_fees_list[scope.$index].podr_fees_usd)"
              @blur="podrForm.podr_fees_list[scope.$index].podr_fees_usd = keepNotAloneNegative(podrForm.podr_fees_list[scope.$index].podr_fees_usd)"
              maxlength="13"
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'podr_fees_list.' + scope.$index + '.podr_fees_remark'">
            <el-input v-model="podrForm.podr_fees_list[scope.$index].podr_fees_remark" maxlength="30" show-word-limit placeholder="暂无备注"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { keep2DecimalContainNegative, keepNotAloneNegative } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';

export default {
  name: 'PartList',
  props: {
    podrForm: {
      type: Object,
      required: true
    },
    isQCDepartment: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keepNotAloneNegative,
    keep2DecimalContainNegative,
    initData() {},
    getSummaries(param) {
      if (this.isQCDepartment) return [];
      const { columns, data } = param;
      let totalList = ['合计', '', '', '', ''];
      columns.forEach((column, index) => {
        let datas = data;
        let totalFive = 0;
        for (let i in datas) {
          totalFive = totalFive + Number(datas[i].podr_fees_usd) * 10000;
        }
        totalFive = Number(totalFive) / 10000;
        totalList[3] = new BigNumber(totalFive).toFixed(2);
      });
      this.$emit('totalExpenses', totalList[3]);
      return totalList;
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
      this.$emit('feesSelect', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
