import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'scon_no', itemType: 'input', label: '销售合同号', sortable: false },
  { prop: 'podr_no', itemType: 'input', label: '采购合同号', sortable: false },
  { prop: 'contract_nos', itemType: 'input', input: false, label: '托收合同号', sortable: false },
  { prop: 'podr_note', itemType: 'input', label: '合同号备注', sortable: false },
  { prop: 'supp_abbr', itemType: 'input', label: '供应商简称', sortable: true },
  {
    prop: 'custAbbrList',
    itemType: 'select',
    options: [],
    multiple: true,
    label: '客户简称',
    sortable: true,
    needOtherColumn: true,
    collapseTags: true,
    formatter: row => row.cust_abbr
  },
  {
    prop: 'custBAbbrList',
    itemType: 'select',
    options: [],
    multiple: true,
    label: '最终客户',
    sortable: false,
    needOtherColumn: true,
    collapseTags: true,
    formatter: row => row.cust_babbr
  },
  { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号', sortable: false },
  {
    prop: 'podr_cndate',
    itemType: 'date',
    label: '合同日期',
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'podr_pedate', itemType: 'date', label: '要求交货日期', sortable: true, formatter: val => getDateNoTime(val, true) },
  { prop: 'podr_ppdate', itemType: 'date', label: '预计付款日期', sortable: false, input: false, formatter: val => getDateNoTime(val, true) },
  { prop: 'cptt_name', itemType: 'input', label: '公司抬头' },
  {
    prop: 'podr_payway',
    itemType: 'select',
    label: '结算方式',
    options: [
      { value: 1, label: '我司结算' },
      { value: 2, label: '工厂结算' }
    ],
    formatter: val => (val === 1 ? '我司结算' : val === 2 ? '工厂结算' : '暂无')
  },
  { prop: 'podr_stff_name', itemType: 'input', label: '采购经办人', sortable: false },
  {
    prop: 'podr_dept_id',
    itemType: 'select',
    label: '采购部门',
    options: [],
    sortable: false,
    needOtherColumn: true,
    formatter: val => val.podr_dept_name
  },
  { prop: 'cust_stff_name', itemType: 'input', label: '销售经办人', sortable: false },
  {
    prop: 'cust_dept_id',
    itemType: 'select',
    label: '销售部门',
    options: [],
    needOtherColumn: true,
    formatter: val => val.cust_dept_name,
    sortable: false
  },
  { prop: 'stff_name', label: '录入人', itemType: 'input', sortable: false },
  { prop: 'create_time', label: '录入时间', itemType: 'date', formatter: val => getDateNoTime(val, true) },
  { prop: 'podc_num', label: '变更次数', itemType: 'input', input: false, sortable: false },
  { prop: 'podr_total', label: '合同金额', itemType: 'input', input: false, sortable: true, align: 'right' },
  { prop: 'podr_prtotal', label: '货款金额', itemType: 'input', input: false, sortable: true, align: 'right' },
  { prop: 'podr_fetotal', label: '费用金额', itemType: 'input', input: false, sortable: true, align: 'right' },
  { prop: 'podr_remark', itemType: 'input', label: '备注', labelWidth: '180px', input: false, sortable: false },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const subTableProperties = [
  {
    label: '产品类型',
    prop: 'podr_category',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: {
      type: 'select',
      options: [
        { value: '新款', label: '新款' },
        { value: '翻单', label: '翻单' }
      ],
      copyAndPaste: true,
      required: true
    }
  },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '数量', prop: 'podr_part_num', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '中文名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '部件名称', prop: 'prod_partno', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '产品描述', prop: 'prod_desc', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '部件单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '已出运数量', prop: 'prod_ship_already', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '单价(元)', prop: 'prod_price', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '金额(元)', prop: 'total_price', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '内包装数量', prop: 'prod_qpb', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外包装数量', prop: 'prod_qpc', itemType: 'input', input: false, sortable: false, labelWidth: 150, subItem: { required: true } },
  { label: '外包装单位', prop: 'prod_box_unit', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '箱数', prop: 'prod_box_num', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '长(CM)', prop: 'prod_boxl', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '宽(CM)', prop: 'prod_boxw', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '高(CM)', prop: 'prod_boxh', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外箱毛重', prop: 'total_gross_weight', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '外箱净重', prop: 'total_net_weight', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '是否主商品', prop: 'prod_mainsup', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val === 1 ? '是' : '否') },
  {
    label: '收货工厂',
    prop: 'supp_bid',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    needOtherColumn: true,
    formatter: row => row.supp_babbr,
    subItem: { type: 'input', copyAndPaste: true }
  },
  {
    label: '要求交货日期',
    prop: 'podr_part_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: false, required: true }
  },
  {
    label: '实际交货日期',
    prop: 'podr_part_redate',
    itemType: 'input',
    input: false,
    sortable: false,
    formatter: val => getDateNoTime(val, false),
    labelWidth: 150,
    subItem: { type: 'date', copyAndPaste: false }
  },

  { label: '备注', prop: 'podr_part_remark', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '产品条形码', prop: 'prod_bar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '内包装条形码', prop: 'prod_inbar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') },
  { label: '外包装条形码', prop: 'prod_outbar', itemType: 'input', input: false, sortable: false, labelWidth: 150, formatter: val => (val ? val : '暂无') }
];
