<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" class="addAndEdit" type="border-card" @tab-click="changeTab">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 ref="childTab1" @changeDocumentNumberLabel="val => (label = val)" @isShow="isShow" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab3 :isShow="isSho" @draftState="draftState" @jumpBackToDocumentInformation="changeTab2" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 ref="childTab2" />
      </el-tab-pane>
      <el-tab-pane label="关联信息" name="fourth" :key="'fourth'">
        <childTab4 />
      </el-tab-pane>
      <el-tab-pane :key="'documentNumber'" :label="label" disabled name="documentNumber" />
    </el-tabs>
  </div>
</template>

<script>
import PodrEditMain from './TabChild/PodrEditMain.vue';
import PodrEditAbout from './TabChild/PodrEditAbout.vue';
import PodrEditAppr from './TabChild/PodrEditAppr.vue';
import RelationList from './TabChild/RelationList.vue';

export default {
  name: 'PodrEdit',
  components: {
    childTab1: PodrEditMain,
    childTab2: PodrEditAbout,
    childTab3: PodrEditAppr,
    childTab4: RelationList
  },
  data() {
    return {
      activeName: 'first',
      isSho: true,
      label: ''
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    changeTab(val) {
      if (val.index === '2') {
        this.$refs.childTab2.trigger();
      }
    },
    changeTab2() {
      this.activeName = 'first';
      this.draftState();
    }
  }
};
</script>

<style lang="scss" scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
::v-deep .el-tabs__nav {
  display: flex;
  width: 100%;
}
::v-deep #tab-documentNumber {
  margin-left: auto;
  color: #909399;
  font-size: 15px;
}
</style>
